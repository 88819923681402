/* eslint-disable no-restricted-globals */ /* eslint-disable
no-unused-expressions */
<template>
  <div style="margin-left: 10px">
    <section slot="pdf-content">
      <h4 style="margin-left: 10px">
        Referral
      </h4>
      <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

        <!-- Header: Left Content -->
        <div style="margin-left: 10px">
          <h5>
            Subject: Free Continuous Glucose Monitoring system
          </h5>

          <!-- Spacer -->
          <hr class="invoice-spacing">
        </div>
      </div>
      <section class="pdf-item">
        <b-row
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="12"
            md="12"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >

              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row class="invoice-spacing">

                  <b-col
                    cols="12"
                    xl="12"
                    class="p-0"
                  >
                    <h5>
                      Message:
                    </h5>
                    <h5 class="mb-25">
                      Dear {{ doctorName }},
                    </h5>
                    <p class="card-text mb-25">
                      I am writing to advise you that your patient, {{ patientData.name }} is utilising a FreeStyle Libre flash glucose monitoring system to assist in managing their glucose levels.

                      Compared with previous blood glucose monitoring using glucose test strips, flash glucose monitoring technology provides deeper insights into glycaemic control including hypoglycaemia, hyperglycaemia and glucose variability. I have attached this patient’s Ambulatory Glucose Profile (AGP) report for your review.
                      If you have LibreView set up, you can also add this patient to your LibreView practice to assist with streamlined consultations including remote monitoring (where appropriate). If you do not have LibreView set up, further support is be available via <a href="https://www.freestyleconnect.com.au">www.freestyleconnect.com.au</a>

                      The following updated list of medications can be considered alongside the AGP report.

                    </p>
                  </b-col>
                </b-row>
              </b-card-body>

            </b-card>
          </b-col>
        </b-row>

      </section>

      <!-- <div class="html2pdf__page-break" /> -->
      <section slot="pdf-content">
        <section class="pdf-item">
          <h4>
            Medication List
          </h4>
          <table class="mt-1">
            <thead>
              <th>Name</th>
              <th>Service Brand</th>
              <th>Strength</th>
              <th>Form</th>
              <th>Directions</th>
            </thead>
            <tbody>
              <!-- <tr
                v-for="item in patientPreAssignedMedicineList"
                :id="item.id"
                :key="item.id"
              >
                <td><strong>{{ item.diabetes_medicine.value }}</strong></td>
                <td><strong>{{ item.diabetes_medicine_brand.value }}</strong></td>
                <td>{{ item.diabetes_medicine_strength }}</td>
                <td>{{ item.form }}</td>
                <td>{{ item.directions }}</td>
              </tr> -->
            </tbody>
          </table>
        </section>
      </section>
    </section></div>
</template>

<script>
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import {
  BRow, BCol, BCard, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: {},
      userData: null,
      patientData: null,
      patientPharmacyData: null,
      spinnerShow: true,
      visitCount: 0,
      isSaving: false,
      patientPreAssignedMedicineList: null,
    }
  },
  computed: {
    currentVisit() {
      return this.visitCount + 1
    },
    recentChangesInDiabetesTheraphyData() {
      if (this.patientData.last_visit !== null) {
        return this.patientData.last_visit.recent_changes_in_diabetes_therapy
      }
      return null
    },
    doctorName() {
      if (this.patientData.last_visit !== null) {
        return this.patientData.last_visit.doctor_name
      }
      return 'Practioner'
    },
    todaysDate() {
      return moment().format('Do MM YY')
    },
  },
  created() {
    this.isSaving = true
    this.fetchPatientInfo()
    this.isSaving = false
  },

  methods: {
    fetchPatientInfo() {
      store
        .dispatch('userStore/fetchPatientInfo', {
          userId: this.userId,
        })
        .then(response => {
          const { status } = response
          if (status === 401) {
            this.$bvToast.toast(`ERROR:${response.message}`, {
              title: 'Error',
              variant: 'danger',
              solid: true,
            })
          } else {
            this.patientData = response.data
            this.patientPharmacyData = response.data.pharmacy
          }
        })
        // .catch(error => {
        //   console.info(error)
        // })
        .catch()
    },
    fetchPreAssignedMedicationList() {
      store
        .dispatch('userStore/fetchPreAssignedService3MedicationList', {
          userId: this.userId,
        })
        .then(response => {
          this.patientPreAssignedMedicineList = response.data.changed
          this.patientService1Medicines = response.data.service_1
          this.nextTodoId = this.patientPreAssignedMedicineList.length + 1
        })
        .catch(() => {})
    },
    generateReport() {
      this.isPDFCreate = true
      // eslint-disable-next-line new-cap
      const doc = new jsPDF()
      /** WITH CSS */
      const canvasElement = document.createElement('canvas')
      html2canvas(this.$refs.pdfcontent, { canvas: canvasElement }).then(canvas => {
        const img = canvas.toDataURL('image/jpeg', 0.8)
        doc.addImage(img, 'JPEG', 20, 20)
        doc.save('sample.pdf')
      })
    },
  },
}
</script>
<style>

</style>
